.addlist .el-icon-plus:before {
  content: "\e6d9";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.promoteBox .rotationName .itemLabel {
  min-width: 6rem;
}
.promoteBox .state .itemLabel {
  min-width: 6rem;
}

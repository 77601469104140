































































































































































































































































































































.addlist {
  .el-icon-plus:before {
    content: "\e6d9";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.promoteBox {
  // .terminalsSet {
  //   .el-cascader.el-cascader--small {
  //     .el-input.el-input--small.el-input--suffix {
  //       width: 400px;
  //     }
  //   }
  // }
  .rotationName {
    .itemLabel {
      min-width: 6rem;
    }
  }
  .state {
    .itemLabel {
      min-width: 6rem;
      // padding-right: 50px;
    }
  }
}
